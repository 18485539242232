import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Review } from '@app/models/performance/review.model';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';

export class StatusColumn extends PillColumn<Review> {
    title = 'Status';
    property = 'status';
    sortField = 'status.id';
    sortable = true;
    type: CellDisplay = CellDisplay.pill;

    getPillDisplay(review: Review): PillDisplay | undefined {
        return {
            type: review.statusPillType,
            label: review.status.toUpperCase(),
        };
    }
}
