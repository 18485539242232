import { Task } from '@app/models/tasks/task.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';

export class TaskStatusColumn extends PillColumn<Task> {
    title = 'table.all-company-tasks.taskStatus';
    type: CellDisplay = CellDisplay.pill;

    constructor() {
        super();

        this.filter = new CheckListFilter('status', [
            { label: 'table.all-company-tasks.overdue', value: 'overdue', initiallyActive: true },
            { label: 'table.all-company-tasks.incomplete', value: 'incomplete', initiallyActive: true },
            { label: 'table.all-company-tasks.completed', value: 'completed' },
        ]);
    }

    getPillDisplay(task: Task): PillDisplay | null {
        if (task.isComplete()) {
            return {
                type: 'success',
                label: 'table.all-company-tasks.pillCompleted',
            };
        }

        if (task.isPastDueDay()) {
            return {
                type: 'danger',
                label: 'table.all-company-tasks.pillOverdue',
            };
        }

        return {
            type: 'default',
            label: 'table.all-company-tasks.pillIncomplete',
        };
    }
}
