import { EmployeeDocument } from '@app/models/documents/employee-document.model';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';
import { LanguageService } from '@app/services';
import { format } from 'date-fns';

export class StatusColumn extends PillColumn<EmployeeDocument> {
    title = 'employees.documents.status';
    property = 'status';
    sortable = true;
    type: CellDisplay = CellDisplay.pill;

    constructor(protected languageService: LanguageService) {
        super();
    }

    getPillDisplay(employeeDocument: EmployeeDocument): PillDisplay | undefined {
        if (!this.shouldShowPill(employeeDocument)) {
            return;
        }

        if (employeeDocument.isProcessing()) {
            return {
                type: 'default',
                label: 'PROCESSING',
            };
        }

        return {
            type: 'warning',
            label: employeeDocument.status,
        };
    }

    getCellText(employeeDocument: EmployeeDocument): string | null {
        if (this.shouldShowPill(employeeDocument)) {
            return '';
        }

        if (employeeDocument.isSignatureRequest() && employeeDocument.isComplete()) {
            return this.languageService.translate({
                key: 'document.signedOnDate',
                params: {
                    date: format(employeeDocument.completedAt(), 'D MMM YYYY'),
                },
            });
        }

        if (employeeDocument.isAcknowledgement() && employeeDocument.isComplete()) {
            return this.languageService.translate({
                key: 'document.acknowledgedOnDate',
                params: {
                    date: format(employeeDocument.completedAt(), 'D MMM YYYY'),
                },
            });
        }

        return employeeDocument.status;
    }

    private shouldShowPill(employeeDocument: EmployeeDocument): boolean {
        return (employeeDocument.isActionable() && !employeeDocument.isComplete()) || employeeDocument.isProcessing();
    }
}
