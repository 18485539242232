import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';

const options = [
    {
        label: 'pending',
        value: 'pending',
    },
    {
        label: 'approved',
        value: 'approved',
    },
];

export class StatusColumn extends PillColumn<TimesheetEntry> {
    title = 'time-tracking.timesheetsIndexPage.table.status';
    property = 'status';
    type: CellDisplay = CellDisplay.pill;

    constructor() {
        super();

        this.filter = new CheckListFilter('status', options);
    }

    getPillDisplay(timesheetEntry: TimesheetEntry): PillDisplay {
        if (timesheetEntry.isApproved) {
            return {
                type: 'success',
                label: 'approved',
            };
        }

        return {
            type: 'primary',
            label: 'pending',
        };
    }
}
