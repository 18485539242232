import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Goal } from '@app/models/performance/goal.model';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';

export class StatusColumn extends PillColumn<Goal> {
    title = 'Status';
    property = 'status';
    sortField = 'status.id';
    sortable = true;
    type: CellDisplay = CellDisplay.pill;

    getPillDisplay(goal: Goal): PillDisplay | undefined {
        return {
            type: goal.statusPillType,
            label: goal.status.toUpperCase(),
        };
    }
}
