import { PillType } from '@app/components/platform/pill/types';
import { TrainingProgramRecord } from '@app/models/training/training-program-record.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';
import { Translatable } from '@app/types/translatable.type';
import { TrainingProgramRecordsStatusDisplayOptions } from '@app/types/translatables/training-display.options';
import { format } from 'date-fns';

export enum TrainingProgramRecordPillTypes {
    pending = 'warning',
    overdue = 'danger',
    complete = 'default',
}

export class StatusColumn extends PillColumn<TrainingProgramRecord> {
    title = 'training.trainingStatus';
    property = 'status';
    type: CellDisplay = CellDisplay.pill;

    constructor() {
        super();

        const options = [
            {
                label: 'training.pending',
                value: 'pending',
            },
            {
                label: 'training.complete',
                value: 'complete',
            },
            {
                label: 'training.overdue',
                value: 'overdue',
            },
        ];

        this.filter = new CheckListFilter('status', options);
    }

    getPillType(trainingProgramRecord: TrainingProgramRecord): PillType {
        return TrainingProgramRecordPillTypes[trainingProgramRecord.status];
    }

    getPillDisplay(trainingProgramRecord: TrainingProgramRecord): PillDisplay | undefined {
        if (trainingProgramRecord.isComplete) {
            return;
        }

        const label =
            TrainingProgramRecordsStatusDisplayOptions[trainingProgramRecord.status ?? ''] ??
            trainingProgramRecord.status.toUpperCase();

        return {
            type: this.getPillType(trainingProgramRecord),
            label,
        };
    }

    getCellText(trainingProgramRecord: TrainingProgramRecord): Translatable | null {
        if (!trainingProgramRecord.isComplete) {
            return;
        }

        return {
            key: 'training.completedOn',
            params: { date: format(trainingProgramRecord.completedAt, 'MMM D, YYYY') },
        };
    }
}
